import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";
import Particle from "../components/Particle";
import project from "../assets/projects/project.jpeg";
import bullet from "../assets/projects/bullet.png";
import pg from "../assets/projects/pg.png"



const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent Top <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bullet}
              isBlog={false}
              title="Bullet Journal"
              description="This online bullet journal platform boasts a responsive front-end design, complete with user authentication using Firebase. This application was co-designed with 7 other UCSD students using HTML, CSS, and Javascript. Users can create individual entries based on dates. Users can also customize each entry with custom bullet points and attach images directly onto the page. "
              ghLink="https://github.com/cse110-sp21-group4/cse110-sp21-group4"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={project}
              isBlog={false}
              title="AI Sudoku Solver"
              description="This AI Sudoku Solver was written entirely in Python as a class project for my Machine Learning class. The user can provide any Sudoku problem and the program will then attempt to solve the problem. The program will show the correct solution if a valid solution exists. "
              ghLink="https://github.com/rsingh84/AI-Sudoku-Solver"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pg}
              isBlog={false}
              title="Huffman File Compression"
              description="This file compression program was written entirely in C++. It utilizes the Huffman Algorithm which is a lossless data compression algorithm to reduce the size of any file without losing the details. The program keeps track of frequently occuring characters and builds a min heap tree. This tree is then used to compress and uncompress files."
              ghLink="https://gitfront.io/r/ranjodhsingh/6ECVK3ewsqAa/Compression-and-Uncompression/"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  )
}

export default Projects